<template>
    <div class="bg-desert">
      <header class="absolute inset-x-0 top-0 z-50">
        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div class="flex lg:flex-1">
            <a href="/" class="-m-1.5 p-1.5">
              <span class="sr-only">Cave Gencel</span>
              <img class="h-14 w-auto animate-fade-right" :src="Logo" alt="" />
            </a>
          </div>
          <div class="flex lg:hidden">
            <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-desert" @click="mobileMenuOpen = true">
              <span class="sr-only">Open main menu</span>
              <Bars3Icon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="hidden lg:flex lg:gap-x-12">
            <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold leading-6 text-white navigation-button animate-fade-down">{{ item.name }}</a>
          </div>
          <div class="hidden lg:flex lg:flex-1 lg:justify-end">
            <SwitchLang/>
          </div>
        </nav>
        <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
          <div class="fixed inset-0 z-50" />
          <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
            <div class="flex items-center justify-between">
              <a href="/" class="-m-1.5 p-1.5">
                <span class="sr-only">Cave Gencel</span>
                <img loading="lazy" class="h-8 w-auto animate-fade-down" :src="Logo" alt="" />
              </a>
              <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-400" @click="mobileMenuOpen = false">
                <span class="sr-only">Close menu</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="mt-6 flow-root">
              <div class="-my-6 divide-y divide-gray-500/25">
                <div class="space-y-2 py-6">
                  <a v-for="item in navigation" :key="item.name" :href="item.href" @click="mobileMenuOpen = false" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-800 navigation-button animate-fade-down">{{ item.name }}</a>
                </div>
                <div class="py-6">
                  <SwitchLang/>
                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
  
      <div class="relative isolate overflow-hidden pt-14">
        <img loading="lazy" :src="HeroImg" alt="" class="absolute inset-0 -z-10 h-full w-full object-cover opacity-50 animate-fade" />
        <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
          <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
        <div class="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div class="text-center">
            <h1 class="text-2xl font-bold tracking-tight text-white sm:text-4xl animate-fade-up">{{whoAreWeText.subTitle}}</h1>
            <div class="mt-10 flex items-center justify-center gap-x-6">
              <a href="mailto:contact@gencel.fr" class="button-cta-primary animate-fade-right">{{ whoAreWeText.ctaPrimary }}</a>
              <a href="https://drive.google.com/file/d/1Ya1dMIAd7pC8wOZa9LpAguDYcEvoylOT/view?usp=drive_link" class="button-cta-secondary animate-fade-left">{{whoAreWeText.ctaSecondary}} <span aria-hidden="true">→</span></a>
            </div>
          </div>
        </div>
        <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
          <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  import { Dialog, DialogPanel } from '@headlessui/vue'
  import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
  import SwitchLang from './subComponents/SwitchLang.vue'


  import Logo from '@/assets/gencelWhiteFR.png'
  import HeroImg from '@/assets/img/hero/hero.png'

  
  const navigation = [
  { name: 'Qui sommes nous ?', href: '#WhoAreWe' },
    { name: 'Nos services', href: '#OurServices' },
    { name: 'Événementiel', href: '#Event' },
    { name: 'Nous trouver', href: '#OurLocation' },
  ]

  const whoAreWeText = 
    { 
      subTitle: "À deux pas du marché Forville dans le quartier historique de Cannes.",
      ctaPrimary :"Nous joindre",
      ctaSecondary :"Notre catalogue"
    }
  
  const mobileMenuOpen = ref(false)
  </script>