<template>
  <div class="component-style py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
        <div class="lg:pr-4">
          <a href="https://maps.app.goo.gl/BQ5exHmafq7jbZNPA" target="_blank">
          <div class="relative overflow-hidden rounded-3xl px-6 pb-9 pt-64 sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10 img-animation animate-fade-right">
            <img loading="lazy" class="absolute inset-0 h-full w-full object-cover" :src="Catalogue" alt="" />
            <div class="absolute inset-0 bg-gray-500 mix-blend-multiply" />
            <div class="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-3xl" aria-hidden="true">
              <div class="aspect-[1097/845] w-[68.5625rem] opacity-50" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
            </div>
            <figure class="relative isolate">
              <svg viewBox="0 0 162 128" fill="none" aria-hidden="true" class="absolute -left-2 -top-4 -z-10 h-32 stroke-white/20">
                <path id="0ef284b8-28c2-426e-9442-8655d393522e" d="M65.5697 118.507L65.8918 118.89C68.9503 116.314 71.367 113.253 73.1386 109.71C74.9162 106.155 75.8027 102.28 75.8027 98.0919C75.8027 94.237 75.16 90.6155 73.8708 87.2314C72.5851 83.8565 70.8137 80.9533 68.553 78.5292C66.4529 76.1079 63.9476 74.2482 61.0407 72.9536C58.2795 71.4949 55.276 70.767 52.0386 70.767C48.9935 70.767 46.4686 71.1668 44.4872 71.9924L44.4799 71.9955L44.4726 71.9988C42.7101 72.7999 41.1035 73.6831 39.6544 74.6492C38.2407 75.5916 36.8279 76.455 35.4159 77.2394L35.4047 77.2457L35.3938 77.2525C34.2318 77.9787 32.6713 78.3634 30.6736 78.3634C29.0405 78.3634 27.5131 77.2868 26.1274 74.8257C24.7483 72.2185 24.0519 69.2166 24.0519 65.8071C24.0519 60.0311 25.3782 54.4081 28.0373 48.9335C30.703 43.4454 34.3114 38.345 38.8667 33.6325C43.5812 28.761 49.0045 24.5159 55.1389 20.8979C60.1667 18.0071 65.4966 15.6179 71.1291 13.7305C73.8626 12.8145 75.8027 10.2968 75.8027 7.38572C75.8027 3.6497 72.6341 0.62247 68.8814 1.1527C61.1635 2.2432 53.7398 4.41426 46.6119 7.66522C37.5369 11.6459 29.5729 17.0612 22.7236 23.9105C16.0322 30.6019 10.618 38.4859 6.47981 47.558L6.47976 47.558L6.47682 47.5647C2.4901 56.6544 0.5 66.6148 0.5 77.4391C0.5 84.2996 1.61702 90.7679 3.85425 96.8404L3.8558 96.8445C6.08991 102.749 9.12394 108.02 12.959 112.654L12.959 112.654L12.9646 112.661C16.8027 117.138 21.2829 120.739 26.4034 123.459L26.4033 123.459L26.4144 123.465C31.5505 126.033 37.0873 127.316 43.0178 127.316C47.5035 127.316 51.6783 126.595 55.5376 125.148L55.5376 125.148L55.5477 125.144C59.5516 123.542 63.0052 121.456 65.9019 118.881L65.5697 118.507Z" />
                <use href="#0ef284b8-28c2-426e-9442-8655d393522e" x="86" />
              </svg>
                <blockquote class="mt-6 text-xl font-semibold leading-8 text-secondary-text">
                  <div class="flex items-center my-2">
                      <svg class="rating-style" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                      </svg>
                      <svg class="rating-style" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                      </svg>
                      <svg class="rating-style" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                      </svg>
                      <svg class="rating-style" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                      </svg>
                      <svg class="rating-style" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 20">
                          <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"/>
                      </svg>
                      <p class="mx-1 text-sm font-bold text-indigo-200 animate-fade-left">{{contentAndTestimonialText.rating}}</p>
                      <p class="mx-1 text-sm font-medium text-[#F8F4E1] animate-fade-left">{{contentAndTestimonialText.ratingOrigin}}</p>
                  </div>
                  <p class="text-[#F8F4E1]">“{{contentAndTestimonialText.testimonial}}”</p>
                </blockquote>
              <figcaption class="mt-6 text-sm leading-6 text-gray-300 animate-fade-left"><strong class="font-semibold text-[#F8F4E1]">{{contentAndTestimonialText.customerName}}</strong>, {{contentAndTestimonialText.customerRole}}</figcaption>
            </figure>
          </div>
          </a>
        </div>
        <div>
          <div class="text-base leading-7 text-gray-700 lg:max-w-lg animate-fade-left">
            <h2>{{contentAndTestimonialText.titleH2}}</h2>
            <div class="max-w-xl text-justify animate-fade-left">
              <h3>{{contentAndTestimonialText.firstSubtitle}}</h3>
              <p>{{contentAndTestimonialText.firstParagraph}}</p>
              <!-- <h3>{{contentAndTestimonialText.secondSubtitle}}</h3>
              <p>{{contentAndTestimonialText.secondParagraph}}</p> -->
              <h3>{{contentAndTestimonialText.thirdSubtitle}}</h3>
              <p>{{contentAndTestimonialText.thirdParagraph}}</p>
            </div>
          </div>
          <dl class="mt-10 grid grid-cols-3 gap-4 border-t border-slate-400 pt-10 animate-flip-down">
            <div v-for="(stat, statIdx) in stats" :key="statIdx">
              <dt class="text-sm font-semibold leading-6 text-secondary-text h-16 animate-flip-down">{{ stat.label }}</dt>
              <dd class="mt-2 text-3xl font-bold leading-10 tracking-tight text-secondary-text animate-flip-down">{{ stat.value }}</dd>
            </div>
          </dl>
          <div class="mt-10 flex">
            <a target="_blank" href="https://maps.app.goo.gl/kEVeEvukz5acznDz7" class="button-cta-primary animate-fade-up">{{contentAndTestimonialText.cta}}<span aria-hidden="true">&rarr;</span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
  import Catalogue from '@/assets/img/photos/catalogue.jpg'

  const stats = [
    { label: 'Fondée en', value: '1937' },
    { label: 'Références', value: '1000 +' },
    { label: 'Température de conservation', value: '15°C' },
  ]

  const contentAndTestimonialText = {
    rating :"4.8",
    ratingOrigin: "(sur Google)",
    testimonial: "Dans une magnifique cave voûtée située dans le centre de Cannes juste à côté du marché Forville, ce caviste fera le bonheur des amoureux des bons vins. Un accueil charmant et toujours de bons conseils.",
    customerName: "N&C",
    customerRole: "Client",

    titleH2:"Qui sommes-nous ?",

    firstSubtitle:"Une cave historique",
    firstParagraph:"Depuis sa création en 1937, trois générations de Gencel se sont succédées dans ce lieu rempli d'histoire jusqu'au départ en retraite de Frédéric en 2023. Hervé et Julien ont pris la relève depuis afin d'assurer la pérennité de cette cave emblématique du quartier historique de Cannes.",

    // secondSubtitle:"Avant tout une histoire de cœur",
    // secondParagraph:"La cave Gencel est avant tout un lieu de rencontre et d'échange aussi bien lors de dégustation que dans le choix de vos vins et spiritueux.",

    thirdSubtitle:"Un savoir-faire précieux",
    thirdParagraph:"Avant de pouvoir intégrer notre sélection, tous les vins ont d'abord été dégustés afin de s'assurer de leur qualité. Quoi de plus normal que de leur offrir un environnement dédié afin de les laisser se bonifier dans les meilleures conditions ? Notre cave de vieillissement est à une température constante de 15 °C.",

    cta:"Venez nous rencontrer"
  }
  </script>