<template>
  <div id="Event" class=" md:flex md:justify-center lg:parent lg:grid lg:h-screen lg:gap-5 bg-desert p-5 mt-32">
      <div class="div1 p-2 lg:h-[60vh]">
          <div class="lg:flex lg:justify-end">
              <div class="">
              <h2 class="text-3xl font-bold tracking-tight sm:text-4xl mb-5 uppercase animate-fade-down">{{catalogText.title}}</h2>
          <div v-for="content in contents" :key="content.icon" class="flex justify-start  icon-scale-animation my-1">
            <dt class="text-center font-semibold leading-7 text-secondary-text flex flex-col items-center">
              <div class=" flex h-10 w-10 items-center justify-center rounded-lg bg-slate-700">
                <component :is="content.icon" class="icon" aria-hidden="true" />
              </div>
            </dt>
            <p class="ml-4 leading-9 text-base text-secondary-text animate-fade-right">{{ content.text }}</p>
          </div>
          <div class="mt-10 flex">
            <a href="https://drive.google.com/file/d/1Ya1dMIAd7pC8wOZa9LpAguDYcEvoylOT/view?usp=drive_link" target="_blank" class="button-cta-primary animate-fade-up">{{catalogText.cta}}<span aria-hidden="true">&rarr;</span></a>
          </div>
      </div>
      </div>
      </div>
      <div class="div2 flex justify-center items-center lg:justify-end lg:items-end mt-10 md:mt-0">
          <img :src="Event10" alt="" class="rounded-xl object-cover h-96 lg:h-[55vh] w-96 md:w-auto img-animation animate-fade-down">
      </div>
      <div class="div3">
          <img :src="Event2" alt="" class="hidden xl:flex rounded-xl object-cover lg:h-[90vh] lg:w-96 img-animation animate-fade-down">
      </div>
      <div class="div4 flex justify-center object-top mt-5">
          <img :src="Event4" alt="" class="md:hidden lg:block rounded-xl object-cover h-96 lg:h-[35vh] w-96 md:w-auto img-animation animate-fade-down object-top">
      </div>
  </div>
  <div>
      
  </div>
</template>
<script setup>
import { BeakerIcon, CakeIcon, ClipboardDocumentCheckIcon,BuildingStorefrontIcon, LockClosedIcon  } from '@heroicons/vue/24/outline'
// import Event1 from '@/assets/img/Catalog/1.jpeg'
import Event2 from '@/assets/img/Catalog/2.jpeg'
// import Event3 from '@/assets/img/Catalog/3.jpeg'
import Event4 from '@/assets/img/Catalog/4.jpeg'

import Event10 from '@/assets/img/Catalog/10.jpg'


const catalogText = {
title: "Event",
cta:"Our wine list"
}


const contents = [
  { 
    icon: BeakerIcon,
    text: 'Cocktail Dinner'
  },
  { 
    icon: CakeIcon,
    text: 'Customized Evening'
  },
  { 
    icon: ClipboardDocumentCheckIcon,
    text: 'Event Organization'
  },
  { 
    icon: BuildingStorefrontIcon,
    text: "Capacity for 150 people"
  },
  { 
    icon: LockClosedIcon,
    text: "Privatization of the 100 m2 store"
  },
]
</script>

<style>
.parent {
grid-template-columns: repeat(3, 1fr);
grid-template-rows: repeat(2, 1fr);
}

.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 1 / 3 / 3 / 4; }
.div4 { grid-area: 2 / 2 / 2 / 3; }
</style>