<template>
    <div class="relative bg-gray-900 lg:h-[70vh]">
      <div class="absolute bottom-0 h-80 w-full xl:inset-0 xl:h-full">
        <div class="h-full w-full xl:grid xl:grid-cols-2">
          <div class="h-full xl:relative xl:col-start-2">
            <img class="h-full w-full object-cover opacity-20 xl:absolute xl:inset-0" :src="yachtImg" alt="People working on laptops" />
            <div aria-hidden="true" class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r" />
          </div>
        </div>
      </div>
      <div class="h-full flex items-center">
      <div class="mx-auto max-w-4xl px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-flow-col-dense xl:grid-cols-2 xl:gap-x-8">
        <div class="relative pb-64 pt-12 sm:pb-64 sm:pt-24 xl:col-start-1 xl:pb-24">
          <h2 class="text-3xl font-bold tracking-tight text-desert uppercase animate-fade-right">{{ourServicesText.title}}</h2>
          <div class="mt-12 space-y-5">
            <p v-for="item in features" :key="item.id">
              <span class="block font-bold text-gray-100 uppercase lg:ml-10 animate-fade-up">{{ item.name }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script setup>
//   import { PaperAirplaneIcon, GlobeAltIcon, BookOpenIcon , AcademicCapIcon, UsersIcon, ArchiveBoxIcon  } from '@heroicons/vue/24/outline'
import yachtImg from '@/assets/img/ourServices/yacht.jpg'

  const ourServicesText = {
  title:"Nos services",
}

const features = [
  {
    name: "Livraisons yachts et villas",
    // icon: PaperAirplaneIcon,
  },
  {
    name: "Livraisons 24 h sur la Côte d'Azur",
    // icon: GlobeAltIcon,
  },
  {
    name: 'Expéditions partout en France et dans le monde',
    // icon: BookOpenIcon,
  },
  {
    name: "Cocktail jusqu'à 150 personnes dans le magasin",
    // icon: AcademicCapIcon,
  },
  {
    name: "Cadeaux d'Entreprise",
    // icon: UsersIcon,
  },
]
  </script>