<template>
  <div class="overflow-hidden component-style py-4 sm:py-32">
    <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
      <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
        <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
          <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
            <h2 class="mt-2 text-3xl font-bold tracking-tight sm:text-4xl">{{ourLocationText.subTitle}}</h2>
            <p>From Tuesday to Saturday from 9:00 AM to 7:00 PM and on Sunday from 9:00 AM to 1:00 PM</p>
            <div class="flex justify-center space-x-10 my-10">
              <a href="tel:0493390898" class="button-cta-primary my-auto duration-150">{{ourLocationText.telContent}}</a>
              <a href="mailto:contact@gencel.fr" class="button-cta-primary duration-150">{{ourLocationText.mailContent}}</a>
              <a href="https://maps.app.goo.gl/BQ5exHmafq7jbZNPA" class="button-cta-primary my-auto duration-150">{{ourLocationText.locationContent}}</a>
            </div>
            <div class="mt-4">
                <div class="flex justify-center space-x-10 my-10">
                  <a v-for="item in navigation.social" :key="item.name" :href="item.href" target="_blank" class=" bg-slate-500 text-[#F8F4E1] icon-scale-animation ">
                    <span class="sr-only">{{ item.name }}</span>
                    <component :is="item.icon" class="h-10" aria-hidden="true" />
                  </a>
                </div>
            </div>
          </div>
        </div>
        <div class="mx-5">
          <iframe class="rounded-lg lg:h-[500px] xl:w-[500px] h-[300px] w-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.62900701652!2d7.008382476933625!3d43.55177477110714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ce81895e088d9d%3A0xbca02cf798d0110a!2sCave%20%C3%A0%20Vins%20Gencel!5e0!3m2!1sfr!2sfr!4v1715343031423!5m2!1sfr!2sfr" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, h } from 'vue'

const ourLocationText = {
  title: "Where to find us",
  subTitle: "Where to find us",
  description: "Located just steps away from the Forville Market in Cannes, you will find our magnificent vaulted cellar.",

  telContent: "Phone",

  mailContent: "Mail",

  locationContent: "Address",

  socialTitle: "Follow Us on Social Media:"
}


const navigation = {
social: [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/people/Cave-Gencel/100091340469911/',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
            'clip-rule': 'evenodd',
          }),
        ]),
    }),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/cavegencel/',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
            'clip-rule': 'evenodd',
          }),
        ]),
    }),
  },
],
}
</script>