<template>
  <footer class="component-style">
    <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
      <p class="text-center text-xs leading-5 text-secondary-text">&copy; {{content}} </p>
    </div>
  </footer>
</template>

<script setup>
const content = "2024 Cave Gencel, all rights reserved.";
</script>